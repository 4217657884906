import {Component, OnInit, TemplateRef} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgxSpinnerService} from 'ngx-spinner';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {AuthService} from '../../providers/auth.service';
import {TrackProgressService} from '../../providers/track-progress.service';


@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
    modalRef: BsModalRef;
    ACCOUNTABILITY = false;
    INSTANT_PAYMENTS = false;
    MORE_CUSTOMERS = false;
    trackingCode;
    facility;
    strapline = '';
    straplineDesc = '';
    username;
    password;
    usertype;
    fieldId;
    config = {
        backdrop: false
    };
    AdminRole;
    loginadmin = true;
    myFacilities: any;

    constructor(private router: Router, private _snackBar: MatSnackBar, private modalService: BsModalService, private spinner: NgxSpinnerService, private spinnerService: Ng4LoadingSpinnerService, public auth: AuthService, public trackprogress: TrackProgressService) {

    }

    ngOnInit() {
        this.setPage();
    }

    setPage() {
        switch (localStorage.getItem('page')) {
            case 'ACCOUNTABILITY': {
                this.ACCOUNTABILITY = true;
                this.INSTANT_PAYMENTS = false;
                this.MORE_CUSTOMERS = false;
                this.strapline = 'clinicPesa | Accountability';
                this.straplineDesc = 'Gain Access To More Accountability And Transparency.';
                break;
            }
            case 'INSTANT_PAYMENTS': {
                this.ACCOUNTABILITY = false;
                this.INSTANT_PAYMENTS = true;
                this.MORE_CUSTOMERS = false;
                this.strapline = 'clinicPesa | Instant Payments';
                this.straplineDesc = 'Start Receiving Your Payments Instantly.';
                break;
            }
            default: {
                this.ACCOUNTABILITY = false;
                this.INSTANT_PAYMENTS = false;
                this.MORE_CUSTOMERS = true;
                this.strapline = 'clinicPesa | Customers';
                this.straplineDesc = 'Gain Access To More Paying Customers.';
                break;
            }
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }
    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, this.config);
    }

    facilityProggress() {
        if (this.trackingCode !== '') {
            this.show();
            this.trackprogress.getfacilityStatus(this.trackingCode).subscribe((data: any) => {

                if (data.status) {
                    // console.log(data.data);
                    this.usertype = 'FacilityOwner';
                    localStorage.setItem('usertype', this.usertype);
                    if (data.data.firstCollectionDate !== '') {
                        if (data.data.businessState === 'pending' || data.data.businessState === 'rejected') {
                            const navigationExtras: NavigationExtras = {
                                state: {
                                    facility: data.data,
                                    registrationStatus: 'messages'
                                }
                            };
                            this.spinnerService.hide();
                            this.router.navigate(['/tracker'], navigationExtras);

                        } else if (data.data.businessState === 'Approved') {
                            // console.log('Approved');
                            const navigationExtras: NavigationExtras = {
                                state: {
                                    facility: data.data,
                                    registrationStatus: 'Approved'
                                }
                            };
                            this.router.navigate(['/tracker'], navigationExtras);

                        } else {
                            const navigationExtras: NavigationExtras = {
                                state: {
                                    facility: data.data,
                                    registrationStatus: 'review'
                                }
                            };
                            this.spinnerService.hide();
                            this.router.navigate(['/tracker'], navigationExtras);

                        }
                    } else {
                        const navigationExtras: NavigationExtras = {
                            state: {
                                facility: data.data,
                                registrationStatus: 'welcome'
                            }
                        };
                        this.spinnerService.hide();
                        this.router.navigate(['/tracker'], navigationExtras);

                    }
                    this.modalRef.hide();
                }
                // this.openSnackBar(data.message.message, "clear")

            });
        } else {
            this.spinnerService.hide();
            // add an alert

            // console.log('enter code');
        }
    }


    show() {
        this.spinnerService.show();
        setTimeout(() => this.spinnerService.hide(), 6000);
    }

    load() {
        this.spinner.show();

        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
        }, 5000);
    }

}
