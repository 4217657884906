import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router,  NavigationExtras } from '@angular/router';
import {TrackProgressService} from 'app/providers/track-progress.service';
import { AuthService } from 'app/providers/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgxSpinnerService } from 'ngx-spinner';
import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  modalRef: BsModalRef;
  trackingCode;
  facility;
  username;
  password ;
  usertype;
  fieldId;
  config = {
    backdrop: false
  };
  AdminRole;
  loginadmin = true;
  myFacilities: any;
  constructor(private _snackBar: MatSnackBar, private modalService: BsModalService, private spinner: NgxSpinnerService, private spinnerService: Ng4LoadingSpinnerService, public auth: AuthService, public trackprogress: TrackProgressService,  public router: Router) {


   }

  ngOnInit() {
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  login() {
    this.load();
    const userCredentials = {
      username: this.username,
      password : this.password
    };

    this.auth.login(userCredentials).subscribe((data: any) => {
      if (data.status) {
        this.modalRef.hide();
        this.spinnerService.hide();
        this.usertype = 'Admin';
        localStorage.setItem('usertype', this.usertype);
        localStorage.setItem('role', data.message);
        this.AdminRole = data.message;
        this.router.navigate(['/data']);
      } else {
        this.spinner.hide();
        // this.openSnackBar(data.message.message, "clear")
        this.openSnackBar('Network', 'clear');
      }
      if (data == null) {
        this.spinner.hide();
        // this.openSnackBar(data.message.message, "clear")
        this.openSnackBar('Network', 'clear');
      }
    });
  }
fieldLogin() {

    this.load();
    this.auth.fieldLogin(this.fieldId).subscribe((data: any) => {
      if (data.status) {
        this.modalRef.hide();
        localStorage.setItem('fieldId', this.fieldId);
        localStorage.setItem('usertype', 'fieldId');

        const navigationExtras: NavigationExtras = {
          state: {
            fieldId: this.fieldId

          }
        };
        this.spinnerService.hide();
        this.router.navigate(['/data'], navigationExtras);

      } else {
        // console.log('user not found');
      }

    });


  }



show() {
this.spinnerService.show();
setTimeout(() => this.spinnerService.hide(), 6000);
}

load() {
  this.spinner.show();

  setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
}


}
