import {Component, OnInit, TemplateRef} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgxSpinnerService} from 'ngx-spinner';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {AuthService} from '../../providers/auth.service';
import {TrackProgressService} from '../../providers/track-progress.service';


@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
    modalRef: BsModalRef;
    trackingCode;
    facility;
    username;
    password;
    usertype;
    fieldId;
    config = {
        backdrop: false
    };
    AdminRole;
    loginadmin = true;
    myFacilities: any;
    // private router: Router;

    constructor(private router: Router, private _snackBar: MatSnackBar, private modalService: BsModalService, private spinner: NgxSpinnerService, private spinnerService: Ng4LoadingSpinnerService, public auth: AuthService, public trackprogress: TrackProgressService) {
    }

    ngOnInit() {
        this.storePage();
        this.trackingCode = '';
    }

    storePage(data: string = 'MORE_CUSTOMERS') {
        localStorage.setItem('page', data);
    }

    openRegister() {
        this.router.navigate(['/register']);
    }

    openDetail(e) {
        this.router.navigate(['/detail']);
        this.storePage(e);
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, this.config);
    }

    track() {
        if (this.trackingCode !== '') {
            // console.log('SENT');
            this.spinner.show();
            this.router.navigate(['/tracker']);
            localStorage.setItem('trackingCode', this.trackingCode);
            this.auth.trackNotifications.next(this.trackingCode);
            this.modalRef.hide();
            // this.router.navigate(['/tracker']);
        }
    }

    checkTracker() {
       return   /^(\s*)?((CM|CF|PF|PM|RF|RM)(\d{2})(\w{7,10}))(\s*)?$/i.test(this.trackingCode.trim());
    }

    show() {
        this.spinner.show();
    }

    load() {
        this.spinner.show();

        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
        }, 5000);
    }

}
