import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControlName} from '@angular/forms';
import {empty} from 'rxjs';
import {AuthService} from 'app/providers/auth.service';
import {Router} from '@angular/router';
// import { ConditionalExpr } from '@angular/compiler';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    form2 = false;
    form1 = true;
    formGroup1: FormGroup;
    documents = [];
    businessform: any;
    fieldOfficer = 'No';
    proof_of_address: any;
    doc3: any;
    governmentbody: any;
    filename: any;
    registrationCode;
    userForm: any;
    count = 0;
    DocError = false;
    DocErrorGov = false;
    DocErrorFile = false;
    License: any;

    constructor(private builder: FormBuilder, private _snackBar: MatSnackBar, public auth: AuthService, private spinner: NgxSpinnerService, private router: Router, private spinnerService: Ng4LoadingSpinnerService) {
        this.userForm = this.builder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)]],
            profile: ['', Validators.required]
        });

       /* this.formGroup1 = this.builder.group({
            lastName: ['Chrispinus', [Validators.required, Validators.pattern(/(.*[a-z]){3}/i)]],
            firstName: ['Onyancha', [Validators.required, Validators.pattern(/(.*[a-z]){3}/i)]],
            email: ['onyancha@clinicPesa.com', [Validators.required, Validators.email, Validators.pattern(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)]],
            facilityName: ['Cp Pharmacy', Validators.required],
            nin: ['CM909090909090', [Validators.required, AuthService.ninValidator]],
            facilityType: ['Hospital', Validators.required],
            accountNumber: ['0773294150', Validators.required],
            position: ['CEO', Validators.required],
            division: ['Kawempe', Validators.required],
            district: ['Kampala', Validators.required],
            businessForm: ['', Validators.required],
            region: ['Central', Validators.required],
            road: ['Lumumba Avenue', Validators.required],
            country: ['UGANDA', Validators.required],
            town: ['Kampala', Validators.required],
            fieldOfficer: ['', Validators.required],
            fieldOfficerID: ['', [Validators.required, AuthService.staffValidator]]
        });*/
               this.formGroup1 = this.builder.group({
            firstName: ['', [Validators.required, Validators.pattern(/(.*[a-z]){3}/i)]],
            lastName: ['', [Validators.required, Validators.pattern(/(.*[a-z]){3}/i)]],
            email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)]],
            facilityName: ['', Validators.required],
            nin: ['', [Validators.required, AuthService.ninValidator]],
            facilityType: ['', Validators.required],
            accountNumber: ['', Validators.required],
            position: ['', Validators.required],
            division: ['', Validators.required],
            district: ['', Validators.required],
            businessForm: ['', Validators.required],
            region: ['', Validators.required],
            road: ['', Validators.required],
            country: ['UGANDA', Validators.required],
            town: ['', Validators.required],
            fieldOfficer: ['', Validators.required],
            fieldOfficerID: ['', [Validators.required, AuthService.staffValidator]]
        });
    }

    ngOnInit() {
        const formData = new FormData();
        this.checkBox('NO');
    }

    next() {

        this.form2 = true;
        this.form1 = false;

        this.businessform = this.formGroup1.get('businessForm').value;

        // console.log(this.formGroup1);
        if (this.businessform === 'sole') {
            // //console.log('vivian');
        }
    }

    checkBox(data: string) {
        if (data === 'YES') {
            this.formGroup1.get('fieldOfficerID').setValue('');
        } else {
            this.formGroup1.get('fieldOfficerID').setValue('CP20000000');
        }
    }

    CheckDoc() {
        console.log(this.filename);
        this.DocErrorFile = this.filename.length <= 2;
        if (this.businessform === 'sole') {
            if (this.documents.length >= 5 && !this.DocErrorFile) {
                this.DocError = false;
                this.register();
            } else {
                this.DocError = !(this.documents.length >= 5);
                // console.log(' The first batch is needed');
            }
        }
        if (this.businessform === 'ngo') {
            if (this.documents.length >= 8 && !this.DocErrorFile) {
                // console.log('good');
                this.DocError = false;
                this.register();
            } else {
                this.DocError = !(this.documents.length >= 8);
                // console.log(' The first batch is needed');
            }
        }
        if (this.businessform === 'partnership') {
            if (this.documents.length >= 5 && !this.DocErrorFile) {
                // console.log('good');
                this.DocError = false;
                this.register();
            } else {
                this.DocError = !(this.documents.length >= 5);
                // console.log(' The first batch is needed');

            }
        }
        if (this.businessform === 'limitedcompany') {
            if (this.documents.length >= 5 && !this.DocErrorFile) {
                // console.log('good');
                this.DocError = false;
                this.register();
            } else {
                this.DocError = !(this.documents.length >= 5);
                // console.log(' The first batch is needed');
                // ask to check all documents
            }
        }
        if (this.businessform === 'parastiatal') {
            if (this.documents.length >= 4 && !this.DocErrorFile) {
                // console.log('good');
                this.DocError = false;
                this.register();
            } else {
                this.DocError = !(this.documents.length >= 4);
                // console.log(' The first batch is needed');
                // ask to check all documents
            }
        }

    }

    register() {
        this.count += 1;
        if (this.count === 1) {
            const document3 = this.doc3;
            const document4 = this.proof_of_address;
            const governmentbodyw = this.governmentbody;

            if (document3) {
                const document = {
                    name: '',
                    docState: 'UNSUPPORTED',
                    reason: ''

                };
                document.name = document3;
                this.documents.push(document);
                // console.log(this.documents);
            } else {
                // console.log('nothing');
            }

            if (document4) {
                const document = {
                    name: '',
                    docState: 'UNSUPPORTED',
                    reason: ''

                };
                document.name = document4;
                this.documents.push(document);
                //  //console.log(this.documents);
            } else {
                // console.log('nothing');
            }

            if (governmentbodyw) {
                const document = {
                    name: '',
                    docState: 'UNSUPPORTED',
                    reason: ''

                };
                document.name = governmentbodyw;
                this.documents.push(document);
                // console.log(this.documents);
            } else {
                // console.log('nothing');
            }
        }
        this.load();

        const facilityData = this.formGroup1.value;
        // facilityData.facilityDocuments = this.documents;

        //  //console.log(facilityData)
        //  this.formGroup1.get('facilityDocuments').setValue(facilityData);
        // console.log('formdata', this.formGroup1);

        const form_data = new FormData();
        form_data.append('licence', this.License, this.License.name);
        form_data.append('facilityDocuments', JSON.stringify(this.documents));


        for (const key in facilityData) {
            form_data.append(key, facilityData[key]);
            this.formGroup1.get('businessForm').value;
        }
        // console.log('form', form_data);


        this.auth.register(form_data).subscribe((data: any) => {

                if (data.status) {
                    this.spinner.hide();
                    this.registrationCode = data.message.state;
                    // console.log(data);
                    // console.log(facilityData['nin']);
                    localStorage.setItem('trackingCode', facilityData['nin']);
                    this.router.navigate(['/tracker']);
                } else {
                    this.openSnackBar(data.message.state, 'clear');

                }

            }
        );


    }

    goback() {


        for (let i = 0; i <= this.documents.length; i++) {
            this.documents.pop();

        }
        // console.log(this.documents);


        this.form1 = true;
        this.form2 = false;
    }

    reSetSelection() {
        this.documents = [];
        this.filename = '';
    }

    getCheckboxV(ev: any, id: string, type: string) {
        this.DocError = false;
        const document = {
            id: id,
            type: type,
            name: '',
            docState: 'UNSUPPORTED',
            reason: ''

        };
        console.log(ev.target.name, id, type );
        const index = this.documents.findIndex(d => d.id === id);
        console.log('Index', index);
        if (index === -1) {
            document.name = ev.target.name;
            this.documents.push(document);
            console.log( this.documents);
        }
    }

    getCheckboxValues(ev: any, id: string, type: string) {
        const doc = ev.target.name;
        this.DocError = false;
        const document = {
            id: id,
            type: type,
            name: '',
            docState: 'UNSUPPORTED',
            reason: ''

        };
        console.log(ev.target.name, id, type );
        const index = this.documents.findIndex(d => d.id === id);
        console.log('Index', index);
        if (index === -1) {
            document.name = doc;
            this.documents.push(document);
            console.log( this.documents);
        } else {
            // val is found, removing from array
            this.documents.splice(index, 1);
            console.log( this.documents);
        }

    }

    onFileChanged(event) {
        this.License = event.target.files[0];
        console.log(this.License);
        if (this.License) {
            this.filename = this.License.name;
            this.DocErrorFile = this.filename.length <= 2 ? true : false;
            console.log(this.filename);
            console.log(this.DocErrorFile);
        } else {
            this.DocErrorFile =  true;
        }
    }

    load() {
        this.spinner.show();

        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
        }, 10000);
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }


}
