import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,   } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators'
@Injectable({
  providedIn: 'root'
})
export class TrackProgressService {
  api_url
  httpOptions;
  constructor(private http: HttpClient) {

    this.httpOptions = {
     headers: new HttpHeaders({
       'Accept':  ' application/json',
       'Content-Type' : 'application/json'
     })
   };
   }

  setCollectiondates(collectionDate) {
    return this.http
    .post(environment.baseURL  +  '/facility/collectionDate', collectionDate, this.httpOptions);

  }

  getfacilityStatus(registrationCode) {
    return this.http
    .get(environment.baseURL + '/facility/fetch/' + registrationCode )
  }
  fetchFacilities() {
    return this.http
    .get(environment.baseURL + '/getregfacilities')
  }
  validDocuments(documents, regcode) {
    return this.http
    .post(environment.baseURL  +  '/validateDocuments/' + regcode, documents, this.httpOptions);
  }
  getmyFacilities(id) {
    return this.http
    .get(environment.baseURL + '/fetchofficerfacilities/' + id)
  }
}
