export const STUDENTS = [
    {
        "error":"0",
        "num_rows":3,
        "data":[
            {
                "SSAD_ID":"10001",
                "STD_NM":"Radhe Shyam Tiwari",
                "SESSION_ID":"2018",
                "ROLLNO":"12345",
                "FATH_NM":"Murli Prasad Sharma",
                "CLS":"6th",
                "PERCENT":"90.00 %"
            },
            {
                "SSAD_ID":"10002",
                "STD_NM":"son 2",
                "SESSION_ID":"2018",
                "ROLLNO":"12345",
                "FATH_NM":"dad",
                "CLS":"6th",
                "PERCENT":"90.00 %"
            },
            {
                "SSAD_ID":"10003",
                "STD_NM":"son 3",
                "SESSION_ID":"2018",
                "ROLLNO":"12345",
                "FATH_NM":"dad",
                "CLS":"6th",
                "PERCENT":"90.00 %"
            },
            {
                "SSAD_ID":"10004",
                "STD_NM":"Radhe Shyam Tiwari",
                "SESSION_ID":"2018",
                "ROLLNO":"12345",
                "FATH_NM":"Murli Prasad Sharma",
                "CLS":"6th",
                "PERCENT":"90.00 %"
            },
            {
                "SSAD_ID":"10005",
                "STD_NM":"Radhe Shyam Tiwari",
                "SESSION_ID":"2018",
                "ROLLNO":"12345",
                "FATH_NM":"Murli Prasad Sharma",
                "CLS":"6th",
                "PERCENT":"90.00 %"
            },
            {
                "SSAD_ID":"10006",
                "STD_NM":"Radhe Shyam Tiwari",
                "SESSION_ID":"2018",
                "ROLLNO":"12345",
                "FATH_NM":"Murli Prasad Sharma",
                "CLS":"6th",
                "PERCENT":"90.00 %"
            }
        ]
    }
];