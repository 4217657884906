import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RegisterComponent} from './components/register/register.component';
import {LandingPageComponent} from './components/landing-page/landing-page.component';
import {DetailComponent} from './components/details/detail.component';
import {TrackerComponent} from './components/tracker/tracker.component';

const routes: Routes = [
    {path: '', component: LandingPageComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'detail', component: DetailComponent},
    {path: 'tracker', component: TrackerComponent}
    // {path: 'welcome', component: WelcomeComponent},
    // {path: 'facilities', component: RegisteredFacilityComponent},
    // {path: 'singlefacility', component: SingleFacilityComponent},
    // {path: 'data', component: DataTableComponent, canActivate: [AuthguardGuard]},
    // {path: 'addFieldofficer', component: AddFieldofficerComponent},s
    // {path: 'fieldofficers', component: FieldofficersComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        paramsInheritanceStrategy: 'always',
        initialNavigation: 'enabled',
        useHash: true
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
