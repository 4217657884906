import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {TrackProgressService} from 'app/providers/track-progress.service';
import {Router, NavigationExtras} from '@angular/router';
import {DatePipe} from '@angular/common';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';


import('@angular/material/datepicker');

@Component({
    selector: 'app-tracker',
    templateUrl: './tracker.component.html',
    styleUrls: ['./tracker.component.scss'],
    providers: [DatePipe]
})
export class TrackerComponent implements OnInit {
    REQUEST = false;
    AWAIT = false;
    SUBMITTED = false;
    REJECTED = false;
    APPROVED = false;
    REVIEW = false;
    RESUBMIT = false;
    TRACK = true;
    modalRef: BsModalRef;
    trackingCode = '';
    config = {
        backdrop: false
    };
    collectionDate;
    revision_date;
    registrationCode;
    facilityDocuments;
    currentStatus;
    usertype;
    facility;
    myDate = new Date();
    ontime;
    collected;
    currentStatusBusiness;
    minDate: Date;
    maxDate: Date;
    reasonDocs = [];
    today: Date;
    firstregistrationCode: any;

    @Input() date: any;
    // welcome = true
    myFilter = (d: Date | null): boolean => {
        const day = (d || new Date()).getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
    };

    constructor(public trackprogress: TrackProgressService, private modalService: BsModalService,  private spinner: NgxSpinnerService, private router: Router,  public auth: AuthService, private datePipe: DatePipe) {

        this.auth.trackNotifications.subscribe((x: any) => {
            // // console.log('ZRECEIVED:');
        });

    }


    ngOnInit() {
        this.trackingCode = '';
        this.mytracking();
        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
        }, 5000);
    }

     mytracking() {
         this.myDate.setDate(this.myDate.getDate() + 1);
         const currentYear = new Date().getFullYear();
         this.registrationCode = localStorage.getItem('trackingCode').toString();
         this.currentStatus = localStorage.getItem('currentStatus');
         this.facilityProgress(this.registrationCode);
         this.minDate = new Date(currentYear);
         this.ontime = this.myDate > new Date(this.collectionDate);
     }

    submitcolDate(coldate) {
        this.load();
        // console.log('this is' + coldate);
        const firstCollection = this.collectionDate;
        // console.log(firstCollection);
        if (coldate === 'collectionDate') {
            const trackingcode = localStorage.getItem('trackingCode');
            const CollectionDate = {
                trackingCode: trackingcode,
                collectionDate: firstCollection,
                firstTime : true
            };
            this.trackprogress.setCollectiondates(CollectionDate).subscribe((data: any) => {
                this.facilityDocuments = this.facility.facilityDocuments;
                if (data.status) {
                    this.setStatus('AWAIT');
                    this.spinner.hide();
                }
            });
        } else if (coldate === 'revisionDate') {
            const revisionDate = {
                trackingCode: this.registrationCode,
                collectionDate: this.revision_date,
                firstTime : false
            };
            this.trackprogress.setCollectiondates(revisionDate).subscribe((data: any) => {
                if (data.status) {
                    this.setStatus('REVIEW');
                    this.collectionDate = this.revision_date;
                    // console.log( new Date() , new Date(this.collectionDate))
                    this.ontime = new Date() > new Date(this.collectionDate);
                    this.spinner.hide();
                }
            });
        }
    }

    gotowelcome() {
        this.currentStatus = 'welcome';
        localStorage.setItem('currentStatus', this.currentStatus);
    }

    facilityProgress(registrationCode: string ) {
        this.today = new Date();
        // // console.log(this.registrationCode);
        this.spinner.show();
        this.trackprogress.getfacilityStatus(registrationCode).subscribe((data: any) => {
            if (data.status) {
                // console.log(data);
                // console.log(data.data.businessState);
                this.facility = data.data;
                this.facilityDocuments = this.facility.facilityDocuments;
                // console.log(this.facilityDocuments)
                this.facilityDocuments.forEach(element => {
                                if (element.docState !== '') {
                                    // console.log('CHCK', element.docState, this.RESUBMIT);
                                    // // console.log('FF', (element.docState === 'INVALID' || element.docState === 'MISSING'));
                                    // // console.log('FF', !this.RESUBMIT);
                                    this.RESUBMIT = (!this.RESUBMIT && (element.docState === 'INVALID' || element.docState === 'MISSING')) ? true : this.RESUBMIT;
                                    this.reasonDocs.push(element);
                                }});
                this.collectionDate = this.facility.firstCollectionDate;
                this.ontime = this.myDate > new Date(this.collectionDate);
                localStorage.setItem('currentStatusBusiness', JSON.stringify(this.facility));
                this.setStatus(data.data.businessState);
            }
            this.spinner.hide();
        });
    }


    setStatus(stat: any) {
        switch (stat) {
            case 'REQUEST' :
                this.REQUEST =  true;
                this.AWAIT = this.SUBMITTED = this.REJECTED = this.APPROVED = this.REVIEW = this.TRACK = false;
                break;
            case 'REJECTED' :
                this.REJECTED = true;
                this.AWAIT = this.SUBMITTED = this.REQUEST = this.APPROVED = this.REVIEW = this.TRACK = false;
                break;
            case 'AWAIT' :
                this.AWAIT = true;
                this.REJECTED = this.SUBMITTED = this.REQUEST = this.APPROVED = this.REVIEW = this.TRACK = false;
                break;
            case 'REVIEW' :
                this.REVIEW = true;
                this.AWAIT = this.SUBMITTED = this.REQUEST = this.APPROVED = this.REJECTED = this.TRACK = false;
                break;
            case 'APPROVED' :
                this.APPROVED = true;
                this.AWAIT = this.SUBMITTED = this.REQUEST = this.REJECTED = this.REVIEW = this.TRACK = false;
                break;
        }
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, this.config);
    }

    track() {
        if (this.trackingCode !== '') {
            this.spinner.show();
            localStorage.setItem('trackingCode', this.trackingCode);
            this.mytracking();
            this.modalRef.hide();
        }
    }

    checkTracker() {
        return   /^(\s*)?((CM|CF|PF|PM|RF|RM)(\d{2})(\w{7,10}))(\s*)?$/i.test(this.trackingCode.trim());
    }


    load() {
        this.spinner.show();

        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
        }, 5000);
    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/']);


    }


}
