import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RegisterComponent} from './components/register/register.component';
import {MatInputModule} from '@angular/material/input';
import {LandingPageComponent} from './components/landing-page/landing-page.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './components/header/header.component';
import {HttpClientJsonpModule} from '@angular/common/http';
import {HttpClientModule} from '@angular/common/http';
import {BackendService} from './providers/backend.service';
import {ModalModule} from 'ngx-bootstrap/modal';


import {MatSelectModule} from '@angular/material/select';
import {Ng4LoadingSpinnerModule} from 'ng4-loading-spinner';
import {NgxSpinnerModule} from 'ngx-spinner';
import {DetailComponent} from './components/details/detail.component';
import {DaysPipe} from './pipes/days.pipe';
import {DataTableComponent} from './data-table/data-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TrackerComponent} from './components/tracker/tracker.component';
import {MatIcon, MatIconModule} from '@angular/material/icon';
// import {RegisteredFacilityComponent} from './components/registered-facility/registered-facility.component';
// import {WelcomeComponent} from './components/welcome/welcome.component';
// import {TrackerComponent} from './components/tracker/tracker.component';
// import {SingleFacilityComponent} from './components/single-facility/single-facility.component';
// import {AddFieldofficerComponent} from './components/add-fieldofficer/add-fieldofficer.component';
// import {FieldofficersComponent} from './components/fieldofficers/fieldofficers.component';

@NgModule({
    declarations: [
        AppComponent,
        RegisterComponent,
        LandingPageComponent,
        HeaderComponent,
        DetailComponent,
        DaysPipe,
        DataTableComponent,
        TrackerComponent,
        // RegisteredFacilityComponent,
        // WelcomeComponent,
        // SingleFacilityComponent,
        // AddFieldofficerComponent,
        // FieldofficersComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FontAwesomeModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ModalModule.forRoot(),
        Ng4LoadingSpinnerModule.forRoot(),
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        NgxSpinnerModule,
        MatSnackBarModule
    ],
    providers: [BackendService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
