import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,   } from '@angular/common/http';
import {environment} from '../../environments/environment';
import { map } from 'rxjs/operators';
import {Router, ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  api_url;
  httpOptions;
  headers;
  trackNotifications: any = new Subject();

    // Validates NIN
    static ninValidator(number: any): any {
        if (number.pristine) {
            return null;
        }
        const NIN_REGEXP = /^(\s*)?((CM|CF|PF|PM|RF|RM)(\d{2})(\w{7,10}))(\s*)?$/i;
        number.markAsTouched();
        if (NIN_REGEXP.test(number.value.trim())) {
            return null;
        }
        return {
            invalidNumber: true
        };
    } // Validates NIN

     static staffValidator(number: any): any {
        if (number.pristine) {
            return null;
        }
        const NIN_REGEXP = /^(\s*)?((CP20)(\w{6,7}))(\s*)?$/i;
        number.markAsTouched();
        if (NIN_REGEXP.test(number.value.trim())) {
            return null;
        }
        return {
            invalidNumber: true
        };
    } // Validates NIN

  constructor(private http: HttpClient) {

    this.httpOptions = {
     headers: new HttpHeaders({
       'Accept':  'application/json',
       'Content-Type' : 'multipart/form-data',
       'boundary': 'l3iPy71otz'
     })

   };
 }




   register(facility) {
    return this.http
    .post(environment.baseURL  +  '/facility/registration', facility, );
  }
  addFieldOfficer(fieldData) {
    return this.http
    .post(environment.baseURL  +  '/facility/add_officer', fieldData );
  }


  login(userCredentials) {
    return this.http
    .get(environment.baseURL + '/login/' + userCredentials.username + '/' + userCredentials.password)
  }
  fieldLogin(id) {
    return this.http
    .get(environment.baseURL + '/fieldLogin/' + id)
  }
  getFieldofficers() {
    return this.http
    .get(environment.baseURL + '/getFieldofficers')
  }



toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
        const value = formValue[key];
        formData.append(key, value);
    }

    return formData;
}

}
